import React, { useState } from 'react';
import { IoMdClose } from 'react-icons/io';
import { Final } from '../assets/Final.js';
import '../Css/Navbar.css';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeLink, setActiveLink] = useState(null);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleNavLinkClick = (index) => {
    setActiveLink(index);
    // Close the menu on mobile when a link is clicked
    if (isMenuOpen) {
      setIsMenuOpen(false);
    }
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div className="navbar-logo">
          <img src={Final.saplogo} alt="Logo" />
          <Link className='logo-main' to="/" onClick={() => setActiveLink(null)}>Sapalogy</Link>
        </div>
        <button className="navbar-toggle" onClick={handleMenuToggle}>
          {isMenuOpen ? <IoMdClose /> : <span>&#9776;</span>}
        </button>
        <ul className={`navbar-menu ${isMenuOpen ? 'navbar-menu-open' : ''}`}>
          <li className="navbar-item">
            <Link to="/" className={` ${activeLink === 0 ? 'active' : ''}`} onClick={() => handleNavLinkClick(0)}>Home</Link>
          </li>
          <li className="navbar-item navbar-dropdown-container">
            <div className="navbar-dropdown">
              <Link to='/about'>About &#9662;</Link>
              <ul className="dropdown-menu">
                <li className='navbar-dropdown-container'>
                  <div className='link-dropdown'><button className='btn'><Link to='/mission' style={{ color: 'black', textDecoration: 'none' }}> Mission, Vision & Values </Link></button></div>
                  <div className='link-dropdown'><button className='btn'><Link to='/chooseus' style={{ color: 'black', textDecoration: 'none' }}>Why Choose Us</Link></button></div>
                  <div className='link-dropdown'><button className='btn'><Link to='/careers' style={{ color: 'black', textDecoration: 'none' }}>Carrers</Link></button></div>
                </li>
              </ul>
            </div>
          </li>
          <li className="navbar-item navbar-dropdown-container">
            <div className="navbar-dropdown">
              <Link>IT Solutions &#9662;</Link>
              <ul className="dropdown-menu">
                <li className='navbar-dropdown-container'>
                  <div className='link-dropdown'><button className='btn link-dropdown'>IT Services</button>
                    <ul className='link-dropdown-menu'>
                      <li><Link to='/softwaredev'>Software Development</Link></li>
                      <li><Link to='/applicationdev'>Application Development</Link></li>
                      <li><Link to='/websitedev'>Web Site Development</Link></li>
                      <li><Link to='/salesforce'>Salesforce</Link></li>
                      <li><Link to='/devops'>DevOps</Link></li>
                      <li><Link to='/digitalmarketing'>Digital Marketing &SEO</Link></li>
                      <li><a href="https://sapalogy.com/cyber_security.html" target="_blank" rel="noopener noreferrer">Cyber Security</a></li>
                    </ul>
                  </div>
                  <div className='link-dropdown'><button className='btn link-dropdown'>Enterprices Solution</button>
                    <ul className='link-dropdown-menu'>
                      <li><Link to='/bankfinance' >Banking & Finance</Link></li>
                      <li><Link to="/insurance">Insurance</Link></li>
                      <li><Link to="/manufacturing">Manufacturing</Link></li>
                      <li><Link to="/healthcare">Healthcare</Link></li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </li>
          <li className="navbar-item navbar-dropdown-container">
            <div className="navbar-dropdown">
              <Link>IT Education &#9662;</Link>
              <ul className="dropdown-menu">
                <li className='navbar-dropdown-container'>
                  {/* <div className='link-dropdown'><button className='btn link-dropdown' >SAP</button>
                    <ul className='link-dropdown-menu'>
                      <li><a href='https://sapalogytraining.com/sap-mm/'>SAP MM 2024</a></li>
                      <li><a href='https://sapalogytraining.com/sap-fico/'>SAP FICO 2024</a></li>
                      <li><a href='https://sapalogytraining.com/sap-abap/'>SAP ABAP</a></li>
                      <li><a href='https://sapalogytraining.com/sap-hcm/'>SAP HCM</a></li>
                      <li><a href='https://sapalogytraining.com/sap-scm/'>SAP SCM</a></li>
                      <li><a href='https://sapalogytraining.com/sap-ariba/'>SAP ARIBA</a></li>
                      <li><a href='https://sapalogytraining.com/sap-pp/'>SAP PP</a></li>
                      <li><a href='https://sapalogytraining.com/sap-sd/'>SAP SD</a></li>
                      <li><a href='https://sapalogytraining.com/sap-fiori/'>SAP FIORI</a></li>
                      <li><a href='https://sapalogytraining.com/sap-qm/'>SAP QM</a></li>
                      <li><a href='https://sapalogytraining.com/sap-wm-ewm/'>SAP WM & EWM</a></li>
                      <li><a href='https://sapalogytraining.com/sap-pm/'>SAP PM</a></li>
                    </ul>
                  </div> */}
                  <div className='link-dropdown'><button className='btn'><a href="https://sapalogytraining.com/salesforce-training/" style={{ color: 'black', textDecoration: 'none' }}>Salesforce</a></button></div>
                  <div className='link-dropdown'><button className='btn'><a href="https://sapalogytraining.com/best-data-analytics-training/" style={{ color: 'black', textDecoration: 'none' }}>Data Analytics</a></button></div>
                  <div className='link-dropdown'><button className='btn'><a href="https://sapalogytraining.com/best-devops-training/" style={{ color: 'black', textDecoration: 'none' }}>DevOps</a></button></div>
                  <div className='link-dropdown'><button className='btn'><a href="https://sapalogytraining.com/best-aws-training/" style={{ color: 'black', textDecoration: 'none' }}>AWS</a></button></div>
                  <div className='link-dropdown'><button className='btn'><a href="https://sapalogytraining.com/best-data-science-training/" style={{ color: 'black', textDecoration: 'none' }}>Data Science</a></button></div>
                  <div className='link-dropdown'><button className='btn'><a href="https://sapalogytraining.com/best-business-analytics-training/" style={{ color: 'black', textDecoration: 'none' }}>Business Analytics</a></button></div>
                  <div className='link-dropdown'><button className='btn'><a href="https://sapalogytraining.com/best-ai-ml-training/" style={{ color: 'black', textDecoration: 'none' }}>AI & ML 2024</a></button></div>
                  <div className='link-dropdown'><button className='btn'><a href="https://sapalogytraining.com/best-machine-learning-training/" style={{ color: 'black', textDecoration: 'none' }}>Machine Learning</a></button></div>
                  <div className='link-dropdown'><button className='btn'><a href="https://sapalogytraining.com/full-stack-developer/" style={{ color: 'black', textDecoration: 'none' }}>Full Stack Development</a></button></div>
                </li>
              </ul>
            </div>
          </li>
          <li className="navbar-item">
  <Link to='/corporate' className={` ${activeLink === 5 ? 'active' : ''}`} onClick={() => handleNavLinkClick(5)}>Corporate Training</Link>
</li>

          <li className="navbar-item"><a href='https://sapalogypvtltd.wordpress.com/'>IT Blog</a></li>
          <li className="navbar-item">
            <Link to='/contactus' className={` ${activeLink === 4 ? 'active' : ''}`} onClick={() => handleNavLinkClick(4)}>Contact</Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
