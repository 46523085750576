import React, { useState } from 'react';
import { IoCallOutline } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import { TiMessages } from "react-icons/ti";

const ContactUs = () => {
  const [hoverIndex, setHoverIndex] = useState(null);
  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const cardData = [
    { 
      icon: (
        <a href="tel:+919175978889" style={styles.link}>
          <IoCallOutline style={styles.icon} />
        </a>
      ), 
      title: "Call Us", 
      description: "Monday to Saturday",
      additionalInfo: "+91 9175978889"
    },
    { 
      icon: (
        <a href="mailto:Paragtkosurkar@gmail.com" style={styles.link}>
          <MdEmail style={styles.icon} />
        </a>
      ), 
      title: "Email Us", 
      description: "Weekdays: 10:30 AM — 7 PM IST",
      additionalInfo: "Paragtkosurkar@gmail.com"
    },
    { 
      icon: (
        <a href="https://chat.whatsapp.com/I0ZGbLs0K5Q8mMFSNmfYOZ" target="_blank" rel="noopener noreferrer" style={styles.link}>
          <TiMessages style={styles.icon} />
        </a>
      ), 
      title: "Community Forum", 
      description: "Monday to Saturday",
      additionalInfo: "Response time: 24 hours"
    }
  ];

  const onSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    formData.append("access_key", "91be6835-0e42-4e81-8f2e-cac881c48c57");

    const object = Object.fromEntries(formData);
    const json = JSON.stringify(object);

    const res = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: json,
    }).then((res) => res.json());

    if (res.success) {
      console.log("Success", res);
      // Clear form fields after successful submission
      setFormValues({
        name: '',
        email: '',
        phone: '',
        message: '',
      });
    } else {
      console.error("Error", res);
    }
  };

  return (
    <div style={styles.contactUs}>
      <div style={styles.cards}>
        {cardData.map((card, index) => (
          <div
            key={index}
            style={{
              ...styles.card,
              ...(hoverIndex === index ? { ...styles.cardHover } : {}),
            }}
            onMouseEnter={() => setHoverIndex(index)}
            onMouseLeave={() => setHoverIndex(null)}
          >
            <div className="icon-container">
              {card.icon}
            </div>
            <p style={{ ...styles.cardP, color: hoverIndex === index ? 'black' : 'black' }}>
              <strong>{card.title}</strong>
            </p>
            <p style={{ ...styles.cardP, color: hoverIndex === index ? 'black' : 'black' }}>
              {card.description}
            </p>
            <p style={{ ...styles.cardP, color: hoverIndex === index ? '' : 'black' }}>
              {card.additionalInfo}
            </p>
          </div>
        ))}
      </div>
      <div style={styles.contactFormSection}>
        <form onSubmit={onSubmit} style={styles.contactForm}>
          <h3>We'd love to hear from you</h3>
          <div style={styles.formGroup}>
            <input
              type="text"
              name="name"
              placeholder="Name"
              style={styles.input}
              value={formValues.name}
              onChange={handleInputChange}
            />
          </div>
          <div style={styles.formGroup}>
            <input
              type="email"
              name="email"
              placeholder="Email"
              style={styles.input}
              value={formValues.email}
              onChange={handleInputChange}
            />
          </div>
          <div style={styles.formGroup}>
            <input
              type="tel"
              name="phone"
              placeholder="Phone Number"
              style={styles.input}
              value={formValues.phone}
              onChange={handleInputChange}
            />
          </div>
          <div style={styles.formGroup}>
            <textarea
              name="message"
              placeholder="Message"
              style={styles.textarea}
              value={formValues.message}
              onChange={handleInputChange}
            ></textarea>
          </div>
          <button type="submit" style={styles.button}>
            Send Message
          </button>
        </form>
      </div>
      <style>
        {`
        .icon-container {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100px;
          height: 100px;
          border-radius: 50%;
          background-color: #fff;
          transition: all 0.5s ease-in-out;
        }

        .icon-container:hover {
          transform: rotateY(360deg);
        }
        
        @keyframes flip {
          0% {
            transform: rotateY(0deg);
          }
          50% {
            transform: rotateY(180deg);
          }
          100% {
            transform: rotateY(360deg);
          }
        }

        @media (max-width: 768px) {
          .icon-container {
            width: 80px;
            height: 80px;
          }
        }

        @media (max-width: 480px) {
          .icon-container {
            width: 60px;
            height: 60px;
          }
        }
        `}
      </style>
    </div>
  );
};

const styles = {
  contactUs: {
    textAlign: 'center',
    padding: '20px',
  },
  cards: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: '20px',
    flexWrap: 'wrap',
  },
  card: {
    width: '300px',
    height: '250px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #ccc',
    borderRadius: '10px',
    transition: 'all 0.3s ease-in-out',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    background: 'white',
    cursor: 'pointer',
    margin: '10px',
  },
  cardHover: {
    height: '300px',
    borderRadius: '20px',
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
    background: '#F0F8FF',
  },
  cardP: {
    marginTop: '0',
  },
  icon: {
    fontSize: '40px',
    marginBottom: '10px',
  },
  contactFormSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '40px',
    padding: '20px',
  },
  contactForm: {
    width: '100%',
    maxWidth: '600px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '30px',
    border: '1px solid #ccc',
    borderRadius: '10px',
  },
  formGroup: {
    marginBottom: '15px',
    width: '100%',
  },
  input: {
    width: '100%',
    padding: '10px',
    borderRadius: '5px',
    border: '1px solid #ccc',
  },
  textarea: {
    width: '100%',
    padding: '10px',
    borderRadius: '5px',
    border: '1px solid #ccc',
    height: '100px',
  },
  button: {
    padding: '10px 20px',
    borderRadius: '5px',
    border: 'none',
    backgroundColor: '#1A73E8',
    color: 'white',
    cursor: 'pointer',
  },
  mapSection: {
    width: '100%',
    maxWidth: '600px',
    marginTop: '40px',
  },
  mapIframe: {
    width: '100%',
    height: '300px',
    border: 'none',
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
};

export default ContactUs;
