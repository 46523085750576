import React, { useState } from 'react';
import backImg from "../../assets/BankFinance/manufact.webp";
import '../../Css/Manufacturing.css'
import { bank } from '../../assets/BankFinance/bank'
import { Link } from 'react-router-dom';
import anotherImg1 from '../../assets/BankFinance/office.webp';


const cards = [
    { id: 1, title: 'Connect huge Data', description: 'Collecting huge data of all manufacturing departments and connecting them to make sense of it is delivered by our solution.' },
    { id: 2, title: 'Easy to Adopt', description: 'Easy to adopt Powerful and fully-agile User interface makes analysing and delivering department wise insight intuitive and familiar to management.' },
    { id: 3, title: 'Deliver Insights', description: 'Funnelling bulk amount of data into a light neural network for analysing and delivering insight for the management.' },
    { id: 4, title: 'Connecting Operations with IoT', description: 'Our solution can connect to industrial sensors to mashup, and analyze data across sensors, devices, and business systems, delivering insights into operational and business knowledge processing.' },
    { id: 5, title: 'Supply Chain and Logistics', description: 'Our solution can Connect directly to internal systems like CRM, ERP, LMS to connect other third party partners’ data and get complete visibility across your supply chain network.' },
    { id: 6, title: 'Sales and Operations Management', description: 'Data around customer behaviour, sales, and forecasting to make adjustments and maximize revenue by Our solution to hel the industry to regulate sales and operation.' },
];
const cards1 = [
    { id: 1, title: 'Detect demand trends of the market and visualise', description: 'This process is crucial for businesses and organizations to make informed decisions regarding product development, marketing strategies, inventory management, and overall business planning based on current market dynamics.' },
    { id: 2, title: 'Optimize pricing strategies', description: 'Optimizing pricing strategies is essential for businesses to maximize revenue, improve competitiveness, and enhance overall financial performance while meeting customer expectations and market demands.' },
    { id: 3, title: 'Regulate fulfilment processes of industry', description: 'Establishes oversight and management mechanisms to ensure that the fulfillment activities within a specific industry are carried out effectively, efficiently, and in accordance with applicable regulations and standards. This oversight is essential for maintaining operational excellence, customer satisfaction, and overall industry competitiveness.' },
    { id: 4, title: 'Improve sales effectiveness with highest revenue', description: 'Improving sales effectiveness with the highest revenue is to achieve significant growth in sales performance and revenue generation, contributing to the overall success and profitability of the business.' },
    { id: 5, title: 'Improve supply chain visibility and forecasting', description: 'Improving supply chain visibility and forecasting enables businesses to operate more efficiently, reduce costs, minimize risks, and better meet customer demands, leading to improved overall performance and competitiveness.' },
    { id: 6, title: 'Achieve cross-functional visibility', description: 'By achieving cross-functional visibility is essential for promoting efficiency, innovation, and synergy within an organization. It helps break down barriers between departments, enhances decision-making processes, and fosters a more cohesive and agile organizational culture. This ultimately contributes to improved performance, customer satisfaction, and overall business outcomes.' },
];
const customeCard=[
    {
        id: 1,
        title:"Data transformation",
        image:bank.card1,
        description: 'Accelerate “Data-to-Insight-to-Action” cycle, by consuming offerings like Data-as-a-Service and Reporting-as-a-Service.',
      },
      {
        id: 2,
        title:"Digital enablement",
        image:bank.card2,
        description: 'Drive key business outcomes, using the full service digital stack – Mosaic, Digital Jedis, and human-centered design experience.',
      },
      {
        id: 3,
        title:"Innovation partnership",
        image:bank.card3,
        description: 'Adopt a collaborative approach to innovation, by leveraging innovation labs, future financial ecosystems, alliances & partners.',
      },
  
]

const manufacturing = () => {

    return (
        <>
            <div>
                <img className="img" src={backImg} />
                <h2 className="heading">Expert IT services for the banking Industry</h2>
            </div>
            <p className="insurance-para">Gathering data and saving in industry is not a problem any more but making sense from those huge data is a big problem for every manufacturing industries now a days. The challenge is to make sense of the data, reveal the patterns in it, use it for business operation improvements, and to support strategic decision making.Manufacturing organizations need to use a Business Analytics that allows timely and flexible visibility across the production lifecycle, driving agility and speed-to-market penetration.</p>

            <div>
                <h4 className="heading-2">Key features that can be Avail by Sapalogy</h4>
                <div className="insurance-card-section">
                    {cards.map((card) => (
                        <div key={card.id} className="insurance-card">
                            <h3>{card.title}</h3>
                            <p>{card.description}</p>
                        </div>
                    ))}
                </div>
            </div>
             <div>
                <h4 className="heading-2">How Sapalogy helpfull For your Manufacturing Industry</h4>
                <div className="insurance-card-section">
                    {cards1.map((card) => (
                        <div key={card.id} className="insurance-card">
                            <h3>{card.title}</h3>
                            <p>{card.description}</p>
                        </div>
                    ))}
                </div>
            </div>

            <div>
                <p className="heading-3">Transform your technology by focusing on 3 key areas</p>
                <div className="custome-card-section">
                    {customeCard.map((card) => (
                        <div className="custome-card" key={card.id}>
                            <img className="custome-img" src={card.image} alt={`Card ${card.id}`} />
                            <p className="custome-title">{card.title}</p>
                            <p className="custome-description">{card.description}</p>
                        </div>
                    ))}
                </div>
            </div>


        </>
    )
}


export default manufacturing;