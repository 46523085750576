import React from 'react';
import '../Css/AboutUs.css';
import backgroundVideo from '../assets/About.mp4';
import bgImg from '../assets/AboutUs.webp';

const AboutUs = () => {
  return (
    <div className="about-us-container">
      <video className="background-video" autoPlay loop muted>
        <source src={backgroundVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <img src={bgImg} alt="Background" className="background-image" />
      <div className="overlay">
        <h1 className="animated-text">OUR STORY</h1>
        <p className="animated-text">
          Sapalogy Pvt. Ltd. is a privately owned IT Support and IT Services business formed in 2012. Today we’re proud to boast a strong team of IT engineers who thrive on rolling up their sleeves and solving your IT problems and meeting your business needs.
        </p>
      </div>
    </div>
  );
};

export default AboutUs;
