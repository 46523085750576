import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Import carousel styles
import './Salesforce.css'; // Import the separated CSS file
import img from '../ItServices/sol-img/sales.png';
import cardImg1 from '../ItServices/sol-img/req.jpg';
import cardImg2 from '../ItServices/sol-img/dev.jpg';
import cardImg3 from '../ItServices/sol-img/consult.jpg';
import cardImg4 from '../ItServices/sol-img/data.jpg';
import cardImg5 from '../ItServices/sol-img/training.jpg';

const cardData = [
  {
    image: cardImg1,
    title: 'Salesforce Implementation',
    paragraph: 'Seamless integration and setup of Salesforce tailored to your business requirements.',
  },
  {
    image: cardImg2,
    title: 'Custom Development',
    paragraph: 'Build custom applications and functionalities on the Salesforce platform to enhance productivity and efficiency.',
  },
  {
    image: cardImg3,
    title: 'Salesforce Consulting',
    paragraph: 'Expert guidance and strategic advice to maximize the value of your Salesforce investment.',
  },
  {
    image: cardImg4,
    title: 'Data Migration and Integration',
    paragraph: 'Smooth and secure transfer of data to Salesforce from existing systems, ensuring data integrity and accessibility.',
  },
  {
    image: cardImg5,
    title: 'User Training and Support',
    paragraph: 'Empower your team with training sessions and ongoing support to leverage Salesforce effectively.',
  },
];

const chooseUsData = [
  {
    heading: 'Industry Expertise',
    paragraph: 'Our team comprises certified Salesforce professionals with years of experience across diverse industries.',
    animation: 'fadeInUp',
  },
  {
    heading: 'Tailored Solutions',
    paragraph: 'We understand that one size doesnt fit all. We craft solutions that align perfectly with your business goals.',
    animation: 'fadeInUp',
  },
  {
    heading: 'Customer-Centric Approach',
    paragraph: 'Your success is our priority. We work closely with you to deliver solutions that drive tangible results.',
    animation: 'fadeInUp',
  },
  {
    heading: 'Continuous Innovation',
    paragraph: 'We stay ahead of the curve by adopting the latest Salesforce technologies and best practices.',
    animation: 'fadeInUp',
  },
];

const Salesforce = () => {
  const [hoveredCard, setHoveredCard] = useState(null);

  return (
    <div>
      <div className="Salesforce">
        <div className="imageContainer">
          <img src={img} alt="Background" className="image" />
          <div className="overlay"></div>
        </div>
        <div className="overlayText">
          <h1 className="paragraph1">
            Welcome to Sapalogy: Your Salesforce Solutions Provider.
          </h1>
          <p className="paragraph">
            We specialize in delivering cutting-edge Salesforce solutions
            tailored to optimize your business operations and drive growth. With
            a deep understanding of the Salesforce ecosystem, our dedicated team
            is committed to transforming your digital landscape.
          </p>
        </div>
      </div>

      <div className="servicesSection">
        <h2 className="servicesHeading">Our Services</h2>
        <p className="servicesParagraph">Unlock the Potential of Salesforce</p>
        <p className="servicesParagraph">
          Harness the power of Salesforce with our comprehensive suite of
          services designed to meet your unique business needs:
        </p>
        <Carousel
          showThumbs={false}
          showStatus={false}
          infiniteLoop={true}
          autoPlay={true}
          interval={1000}
          className="carousel"
        >
          {cardData.map((card, index) => (
            <div key={index} className="card">
              <img
                src={card.image}
                alt={`Card ${index + 1}`}
                className="cardImage"
              />
              <h3 className="cardTitle">{card.title}</h3>
              <p className="cardParagraph">{card.paragraph}</p>
            </div>
          ))}
        </Carousel>
      </div>

      <div className="chooseUsSection">
        <h2 className="chooseUsHeading">Why Choose Us</h2>
        <div className="chooseUsContent">
          {chooseUsData.map((item, index) => (
            <div
              key={index}
              className={`chooseUsBox ${item.animation}`}
            >
              <h3 className="chooseUsBoxHeading">{item.heading}</h3>
              <p className="chooseUsBoxParagraph">{item.paragraph}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Salesforce;
