import bank1 from './bank1.svg'
import bank2 from './bank2.svg'
import bank3 from './bank3.svg'
import bank4 from './bank4.svg'
import bank5 from './bank5.svg'
import bank6 from './bank6.svg'
import card1 from './1.webp'
import card2 from './2.webp'
import card3 from './3.webp'
import man from './manufact.webp'
import health from './health.webp'

export const bank={
    bank1,
    bank2,
    bank3,
    bank4,
    bank5,
    bank6,
    card1,
    card2,
    card3,
    man,
    health,
}