import React from 'react';
import '../ItServices/WebsiteDev.css';
import WDIMG from '../../../assets/WebsiteDev.svg';

const App = () => {
  return (

    <>
    <div>
      <div>
        <img className="WDIMG" src={WDIMG} alt="Website Development" />
        <div className="content-box">
          <h2>Custom Mobile App Development Service:</h2>
          <h3>Delivering Functionality, Transforming Experiences</h3>
          <p>
            We are not just another mobile app development company here to build apps – we are here to build apps that transform experiences and stimulate business growth. Users today are spoilt for choices. With new apps germinating every day, the only way to stand out and win user loyalty is to appeal to their experience – and that’s what we will help with. By integrating sophisticated designs, top-notch technology, real-time data and analytics, our team here gives to you the best mobile design solutions to help you achieve your business goals seamlessly. So, don’t just get another mobile app. Get the game-changing mobile app.
          </p>
        </div>
        <div className="points-container">
          <div className="point-box">
            <h3>Recommendation Engine</h3>
            <p>It uses the user's history to suggest similar products or services. Just like the suggestions while shopping from Amazon or watching Netflix.</p>
          </div>
          <div className="point-box">
            <h3>Predicted Health Monitoring</h3>
            <p>ML based apps can help doctors to treat patients in advance and save lives by having hands-on predictions based on their patient data.</p>
          </div>
          <div className="point-box">
            <h3>Chatbots For Customer Support</h3>
            <p>Intelligent bots programmed to automate multiple business processes and customer service with instant, precise information.</p>
          </div>
          <div className="point-box">
            <h3>Gamified Learning & Education</h3>
            <p>ML-driven statistical model is built using students' answers which determines their memory cycle and ping them for revisions.</p>
          </div>
          <div className="point-box">
            <h3>Sorted, Tagged & Categorized Photos</h3>
            <p>Image categorization simplifies the search process. It’s like searching a restaurant and getting the menu, food, ambience, etc. in results.</p>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default App;
