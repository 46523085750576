import React from 'react';
import '../ItServices/ApplicationDev.css'; // Import the CSS for this component
import TopImage from '../../../assets/app_dev.webp'; // Import your top image
import Logo1 from '../../../assets/L1.webp'; // Import your logo images
import Logo2 from '../../../assets/L2.webp';
import Logo3 from '../../../assets/L3.webp';
import Logo4 from '../../../assets/L4.webp';
import Logo5 from '../../../assets/L5.webp';
import Logo6 from '../../../assets/L6.webp';
import IDE from '../../../assets/IDE.webp';
import Android from '../../../assets/android.webp'
import Hybrid from '../../../assets/hybrid.webp'
import Testing from '../../../assets/testing app.webp'
import iOS from '../../../assets/IOS.webp'
import Cross from '../../../assets/cross-platform-app.webp'
import Slider from 'react-slick';
const sliderItems = [
  { img: Android, title: 'Android', text: 'Languages: Kotlin, Java, C++ Frameworks and tools: Android SDK, Google Services (Maps, Game, OAuth, Analytics, etc.), Volley, RetroFit, Gson, EventBus, Google VR, Samsung Gear VR' },
  { img: Hybrid, title: 'Hybrid', text: 'Apache Cordova, Ionic, Meteor, Mobile Angular UI, Polymer Project, Sencha Touch' },
  { img: Testing, title: 'Testing', text: 'JUnit, UI Automation, Espresso, Cucumber, UiAutomator' },
  { img: iOS, title: 'iOS', text: 'Languages: Swift, Objective-C Frameworks and tools: Cocoa, Reactive Cocoa, Typhoon, Socket.IO, WebRTC, RestKit, AppleTV, SQLite, MS SQL, UIKit Core Data Location Services' },
  { img: Cross, title: 'Cross-platform', text: 'React Native, Flutter' },
  { img: IDE, title: 'IDE', text: 'XCode, Android Studio, Eclipse, WebStorm' }
];
const ApplicationDev = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  return (
    <>
      <img src={TopImage} alt="Top" className="top-image" />
      <div className="new-page-container">
        <div className="ad-content">
          <h2 className="main-heading">Custom Mobile App Development Service: Delivering Functionality, Transforming Experiences</h2>
          <p className="main-paragraph">
            We are not just another mobile app development company here to build apps – we are here to build apps that transform experiences and stimulate business growth. Users today are spoilt for choices. With new apps germinating every day, the only way to stand out and win user loyalty is to appeal to their experience – and that’s what we will help with. By integrating sophisticated designs, top-notch technology, real-time data and analytics, our team here gives to you the best mobile design solutions to help you achieve your business goals seamlessly.</p>
        </div>

        <div className="flowchart-container">
          <h2 className="flowchart-heading">CHOOSE YOUR SERVICE OPTION</h2>

          <div className="flowchart-row">
            <div className="flowchart-item">
              <h3>Software Consulting</h3>
              <p>With proven capabilities, our subject-matter experts will assess your problem and come up with possible software solutions. They’ll advise on techs and architecture design, as well as provide the scalability and security roadmaps, a rough estimation of time and costs, and more.</p>
            </div>
          </div>

          <div className="flowchart-row">
            <div className="flowchart-item">
              <h3>End-To-End Software Development</h3>
              <p>Bringing together a pool of experts with various skills and expertise, we will support you through the full software development cycle – from initial needs analysis to the implementation and adoption of the new software solution.</p>
            </div>
          </div>

          <div className="flowchart-row">
            <div className="flowchart-item">
              <h3>Legacy Software Modernization</h3>
              <p>Intelligent bots programmed to automate multiple business processes and customer service with instant, precise information.</p>
            </div>
          </div>

          <div className="flowchart-row">
            <div className="flowchart-item">
              <h3>Integration</h3>
              <p>We can integrate new software with other tools you already use – be they custom-made or platform-based – like ERP, CRM, PDM, MES, vendor and customer portals, and more.</p>
            </div>
          </div>

          <div className="flowchart-row">
            <div className="flowchart-item">
              <h3>API Development</h3>
              <p>Our experts can build clean, secure and well-documented APIs to help you extend enterprise-wide automation or enable smooth and easy integration of your custom solution with other internal or external systems and subsystems.</p>
            </div>
          </div>
        </div>
        <div className='slider-div'>
          <div className="slider-container">
            <h2 className="slider-heading">Mobile Application Development Technologies</h2>
            <Slider className='Slider-main' {...settings}>
              {sliderItems.map((item, index) => (
                <div key={index} className="slider-item">
                  <div className="slider-img">
                    <img src={item.img} alt={item.title} />
                  </div>
                  <div className="slider-content">
                    <h3>{item.title}</h3>
                    <p>{item.text}</p>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>



        <div className="logo-container">
          <h2 className="logo-heading">MULTIPLE VERTICLE EXPERTISE</h2>
          <div className="logo-item" data-aos="flip-left" data-aos-duration="1000">
            <img src={Logo1} alt="Logo 1" className="logo-img" />
            <h3>TRAVEL & HOSPITALITY</h3>
          </div>

          <div className="logo-item" data-aos="flip-left" data-aos-duration="1000">
            <img src={Logo2} alt="Logo 2" className="logo-img" />
            <h3>REAL ESTATE & PROPERTY</h3>
          </div>

          <div className="logo-item" data-aos="flip-left" data-aos-duration="1000">
            <img src={Logo3} alt="Logo 3" className="logo-img" />
            <h3>EDUCATION & ELEARNINING</h3>
          </div>

          <div className="logo-item" data-aos="flip-left" data-aos-duration="1000">
            <img src={Logo4} alt="Logo 4" className="logo-img" />
            <h3>E-COMMERCE, RETAIL & B2B
            </h3>
          </div>

          <div className="logo-item" data-aos="flip-left" data-aos-duration="1000">
            <img src={Logo5} alt="Logo 5" className="logo-img" />
            <h3>MEDIA & ENTERTAINMENT</h3>
          </div>

          <div className="logo-item" data-aos="flip-left" data-aos-duration="1000">
            <img src={Logo6} alt="Logo 6" className="logo-img" />
            <h3>HEALTHCARE & FITNESS</h3>
          </div>
        </div>
      </div>
    </>

  );
};

export default ApplicationDev;
