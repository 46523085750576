import React, { useState, useEffect } from "react";

const ConnectUsForm = () => {
  const [isVisible, setIsVisible] = useState(false); // Control visibility
  const [isMinimized, setIsMinimized] = useState(false); // Control minimize
  const [formData, setFormData] = useState({
    name: "",
    contactNo: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const web3FormKey = "91be6835-0e42-4e81-8f2e-cac881c48c57"; // Add your Web3Form key here

  // Show form after 7 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 7000); // 7 seconds

    return () => clearTimeout(timer); // Cleanup timer
  }, []);

  // Handle input changes
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    // Prepare data for Web3Form
    const formBody = new URLSearchParams();
    formBody.append("access_key", web3FormKey);
    formBody.append("name", formData.name);
    formBody.append("contactNo", formData.contactNo);

    try {
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        body: formBody,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      const result = await response.json();

      if (result.success) {
        setIsSubmitted(true); // Display success message
        console.warn("clear more");
        // Clear form after submission
        setFormData({
          name: "",
          contactNo: "",
        });
      } else {
        console.error("Form submission failed:", result.message);
        setFormData({
          name: "",
          contactNo: "",
        });
      }
    } catch (error) {
      setFormData({
        name: "",
        contactNo: "",
      });
      console.error("Error submitting form:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  // Minimize form
  const minimizeForm = () => {
    setIsMinimized(true);
  };

  // Restore form
  const restoreForm = () => {
    setIsMinimized(false);
  };

  return (
    <>
      {isVisible && !isMinimized && !isSubmitted && (
        <div style={styles.popup}>
          <button style={styles.minimizeButton} onClick={minimizeForm}>
            _
          </button>
          <h3 style={styles.heading}>Get Callback in 24 hrs.</h3>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              name="name"
              placeholder="Name"
              value={formData.name}
              onChange={handleChange}
              style={styles.input}
              required
            />
            <input
              type="tel"
              name="contactNo"
              placeholder="Contact No."
              value={formData.contactNo}
              onChange={handleChange}
              style={styles.input}
              required
            />
            <button
              type="submit"
              style={styles.submitButton}
              disabled={isSubmitting}
            >
              {isSubmitting ? "Submitting..." : "Submit"}
            </button>
          </form>
        </div>
      )}

      {isVisible && isSubmitted && (
        <div style={styles.popup}>
          <h3 style={styles.heading}>Thank you! We'll contact you soon.</h3>
        </div>
      )}

      {isMinimized && (
        <div style={styles.stickyIcon} onClick={restoreForm}>
          📩
        </div>
      )}
    </>
  );
};

// Updated styles for the pop-up and sticky icon
const styles = {
  popup: {
    position: "fixed",
    bottom: "10%",
    right: "5%",
    backgroundColor: "rgba(0,0,0,0.5)",
    color: "#fff",
    padding: "20px",
    borderRadius: "15px",
    boxShadow: "0 8px 16px rgba(0, 0, 0, 0.3)",
    width: "90%",
    maxWidth: "400px",
    zIndex: 1000,
    transition: "all 0.3s ease-in-out",
  },
  heading: {
    textAlign: "center",
    marginBottom: "15px",
  },
  input: {
    display: "block",
    width: "100%",
    padding: "12px",
    marginBottom: "12px",
    borderRadius: "5px",
    border: "none",
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
    fontSize: "16px",
  },
  submitButton: {
    padding: "12px 20px",
    backgroundColor: "#448EE2",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    fontSize: "16px",
    cursor: "pointer",
    width: "100%",
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.2)",
    transition: "background-color 0.3s ease-in-out",
  },
  minimizeButton: {
    position: "absolute",
    top: "8px",
    right: "8px",
    cursor: "pointer",
    background: "none",
    border: "none",
    color: "#fff",
    fontSize: "18px",
  },
  stickyIcon: {
    position: "fixed",
    bottom: "8%",
    right: "5%",
    fontSize: "40px",
    cursor: "pointer",
    color: "#667eea",
    zIndex: 1000,
  },
  "@media (max-width: 600px)": {
    stickyIcon: {
      position: "fixed",
      bottom: "8%",
      right: "15px",
      fontSize: "40px",
      cursor: "pointer",
      color: "#667eea",
      zIndex: 1000,
    },
  },
  "@media (max-width: 600px)": {
    fontSize: "14px",
  },
};

export default ConnectUsForm;
