import React from 'react';
import { FaLaptop, FaUsers, FaCheckCircle, FaChartLine } from 'react-icons/fa';
import bg from '../assets/corporate.jpg';
import img1 from '../assets/corp1.png';
import img2 from '../assets/corp2.png';
import img3 from '../assets/corp3.png';
import img4 from '../assets/corp4.png';

const Corporate = () => {
  const cardStyle = {
    border: '1px solid #ddd',
    borderRadius: '8px',
    padding: '20px',
    textAlign: 'center',
    flex: '1',
    margin: '10px',
    maxWidth: '300px',
    background: '#fff',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
  };

  const containerStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: '30px',
  };

  const backgroundStyle = {
    backgroundImage: `url(${bg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    minHeight: '70vh',
    padding: '50px 20px',
    color: '#fff',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  };

  const overlayStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    zIndex: 1,
  };

  const headingStyle = {
    zIndex: 2,
    margin: '0',
    fontSize: '2.5rem',
    
  };

  const pointHeadingStyle = {
    fontSize: '1.8rem',
    margin: '20px 0',
    textAlign: 'center',
    color:'#007BFF',
  };

  const pointContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '30px 0',
  };

  const pointStyle = {
    display: 'flex',
    alignItems: 'center',
    margin: '10px 0',
    maxWidth: '600px',
    padding: '15px',
    border: '1px solid #ddd',
    borderRadius: '8px',
    background: '#fff',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    textAlign: 'left',
    width: '90%',
    maxWidth: '600px',
  };

  const iconStyle = {
    marginRight: '15px',
  };

  const photosContainerStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    margin: '40px 0',
    padding: '20px',
  };

  const photoHeadingStyle = {
    fontSize: '1.8rem',
    margin: '20px 0',
    textAlign: 'center',
    color:'#007BFF',
  };

  const photoStyle = {
    flex: '1 1 100%', // Full width for small screens
    maxWidth: '300px',
    margin: '10px',
    textAlign: 'center',
  };

  const photoImageStyle = {
    width: '100%',
    height: 'auto',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
  };

  const contentStyle = {
    textAlign: 'center',
    padding: '20px',
    background: '#f8f8f8',
    borderRadius: '8px',
    margin: '20px auto',
    maxWidth: '800px',
  };

  return (
    <div>
      <div style={backgroundStyle}>
        <div style={overlayStyle}>
          <h1 style={headingStyle}>Corporate Training Partner</h1>
        </div>
      </div>

      <div style={containerStyle}>
        <div style={cardStyle}>
          <FaLaptop size={50} color="#007bff" />
          <h3>Customized Online and Offline Training</h3>
          <p>Aided by comprehensive notes and live projects</p>
        </div>
        <div style={cardStyle}>
          <FaUsers size={50} color="#007bff" />
          <h3>One-on-One Doubt Solving</h3>
          <p>Project planning to execution pedagogy for basic to advance learning</p>
        </div>
        <div style={cardStyle}>
          <FaCheckCircle size={50} color="#007bff" />
          <h3>Post Training Quality Testing</h3>
          <p>To check and verify their output potential</p>
        </div>
        <div style={cardStyle}>
          <FaChartLine size={50} color="#007bff" />
          <h3>Skilled Employees</h3>
          <p>Directly translate to optimal software utility, faster project execution, and higher profits</p>
        </div>
      </div>

      {/* Point Heading */}
      <h2 style={pointHeadingStyle}>How Are We Different</h2>
      <div style={pointContainerStyle}>
        <div style={pointStyle}>
          <FaUsers size={40} color="#007bff" style={iconStyle} />
          <div>
            <h3>Unbeatable Trust-Based Partnership</h3>
            <p>Up to 3 months of support to ensure successful implementation.</p>
          </div>
        </div>
        <div style={pointStyle}>
          <FaCheckCircle size={40} color="#007bff" style={iconStyle} />
          <div>
            <h3>Comprehensive Notes and Live Projects</h3>
            <p>Our materials are tailored to match your company's needs.</p>
          </div>
        </div>
        <div style={pointStyle}>
          <FaChartLine size={40} color="#007bff" style={iconStyle} />
          <div>
            <h3>Flexible Scheduled Classes</h3>
            <p>Classes can be scheduled according to your company’s needs, on or off premises.</p>
          </div>
        </div>
      </div>

      {/* Photo Heading */}
      <h2 style={photoHeadingStyle}>Our Sustainability Goals and Corporate Social Responsibility</h2>
      <div style={photosContainerStyle}>
        <div style={photoStyle}>
          <img src={img1} alt="Corporate Responsibility 1" style={photoImageStyle} />
        </div>
        <div style={photoStyle}>
          <img src={img2} alt="Corporate Responsibility 2" style={photoImageStyle} />
        </div>
        <div style={photoStyle}>
          <img src={img3} alt="Corporate Responsibility 3" style={photoImageStyle} />
        </div>
        <div style={photoStyle}>
          <img src={img4} alt="Corporate Responsibility 4" style={photoImageStyle} />
        </div>
      </div>

      <div style={contentStyle}>
        <p>We conduct regular seminars in tech colleges to create awareness on latest Data science, AI-ML, Analytics upskilling amongst youths as a part of our social responsibility.</p>
        <p>Our courses are specifically targeted towards bridging the gap between talent and employers in India while focusing on best sustainability practices.</p>
      </div>
    </div>
  );
};

export default Corporate;
