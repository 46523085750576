import React from 'react';
import '../Css/Card.css';
import Manage from '../assets/Managed_It_Services.avif'
import IT from '../assets/IT.webp'
import Enterprise from '../assets/Enterprise-solutions.webp'
const cards = [
  {
    image:Manage ,
    title: 'Managed IT Services',
    description: 'Managed IT services allow businesses to delegate their IT operations to an expert third-party organization.',
  },
  {
    image: IT,
    title: 'Enterprises Solutions',
    description: 'enterprise solutions company that answers todays issues, anticipates tomorrows needs and builds next-generation solutions.Managed IT services allow businesses to delegate their IT operations to an expert third-party organization.',
  },
  {
    image: Enterprise,
    title: 'IT Education',
    description: 'Learn from industrial experts in simplified way. Training, Certification and internships for dedicated candidates.',
  },
];

const Card = ({ image, title, description }) => {
  return (
    <div className="main-card">
      <img src={image} alt={title} className="main-card-image" />
      <div className="card-content">
        <h3  className="card-title">{title} </h3>
        <p className="card-description">{description}</p>
      </div>
    </div>
  );
};

function App() {
  return (
    <div className="App">
      <div className="card-grid">
        {cards.map((card, index) => (
          <Card
            key={index}
            image={card.image}
            title={card.title}
            description={card.description}
          />
        ))}
      </div>
    </div>
  );
}

export default App;
