import React from 'react';
import { Link } from 'react-router-dom';
import anotherImg1 from '../assets/office.webp'; // Replace with the actual path to your image
import '../Css/Footer.css'; // Assuming this is the path to your CSS file

const MainComponent = () => {
  const styles = {
    newSection: {
      position: 'relative',
      width: '100%',
      height: '550px',
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '50px',
    },
    newImage: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: 0,
    },
    newOverlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: 1,
    },
    newOverlayText: {
      position: 'relative',
      color: 'white',
      zIndex: 2,
      textAlign: 'center',
      padding: '20px',
    },
    newHeading: {
      fontSize: '2.0rem',
      marginBottom: '10px',
    },
    newParagraph: {
      fontSize: '1.2rem',
      marginBottom: '20px',
    },
    transparentButton: {
      border: '2px solid white',
      backgroundColor: 'transparent',
      color: 'white',
      padding: '10px 20px',
      fontSize: '1rem',
      borderRadius: '5px',
      cursor: 'pointer',
      transition: 'background-color 0.3s ease',
      textDecoration: 'none',
      marginTop: '20px',
    },
    link: {
      textDecoration: 'none',
    },
    
  };

  return (
    <div>
      {/* New section with image, heading, paragraph, and button */}
      <div style={styles.newSection}>
        <img src={anotherImg1} alt="New Section Background" style={styles.newImage} />
        <div style={styles.newOverlay}></div>
        <div className='heading' style={styles.newOverlayText}>
          <h2 className='contactH2' style={styles.newHeading}>Are you ready for a better, more productive business?</h2>
          <p style={styles.newParagraph}>
            Stop worrying about technology problems. Focus on your business. Let us provide the support you deserve.
          </p>
          <Link to='/contactus' style={styles.link}>
            <button style={styles.transparentButton}>Contact Us Now</button>
          </Link>
        </div>
      </div>

      {/* Footer Component */}
      <footer className="footer">
        <div className="footer-container">
          <hr className="footer-divider" />
          <div className="footer-sections">
            <div className="footer-section">
              <h3>Company</h3>
              <ul>
              <li><Link to="/about">About</Link></li>
                <li><Link to="/mission">Mission, Vision, Values</Link></li>
                <li><Link to="/chooseus">Leadership Team</Link></li>
                <li><Link to="/locations">Locations</Link></li>
                <li><Link to="/careers">Careers</Link></li>
              </ul>
            </div>
            <div className="footer-section">
              <h3>About Us</h3>
              <ul>
                <li><Link to="/education">IT Education</Link></li>
                <li><Link to="/salesforce">SAP</Link></li>
                <li><Link to="/salesforce">Salesforce</Link></li>
                <li><Link to="/data-analytics">Data Analytics</Link></li>
                <li><Link to="/data-science">Data Science</Link></li>
              </ul>

            </div>
            <div className="footer-section">
              <h3>IT Services</h3>
              <ul>
                <li><Link to="/softwaredev">Software Development</Link></li>
                <li><Link to="/applicationdev">Application Development</Link></li>
                <li><Link to="/websitedev">Web Development</Link></li>
                <li><Link to="/digitalmarketing">Digital Marketing and SEO</Link></li>
                <li><Link to="/cyber-security">Cyber Security</Link></li>
              </ul>
            </div>
            <div className="footer-section">
              <h3>SAP Solutions</h3>
              <ul>
                <li><Link to="/enterprise-solutions">Enterprise Solutions</Link></li>
                <li><Link to="/bankfinance">Banking & Finance</Link></li>
                <li><Link to="/insurance">Insurance</Link></li>
                <li><Link to="/it-solutions">IT Solutions</Link></li>
                <li><Link to="/manufacturing">Manufacturing</Link></li>
                <li><Link to="/healthcare">Healthcare</Link></li>
              </ul>
            </div>
          </div>
          <div className="footer-social">
            <a href="https://www.facebook.com/Sapalogy/" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i></a>
            <a href="https://www.instagram.com/sapalogy_pvt.ltd/?hl=en" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a>
            <a href="https://x.com/Sapalogy_ltd" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i></a>
            <a href="https://www.linkedin.com/company/sapalogy-pvt-ltd/?originalSubdomain=in" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin-in"></i></a>
            <a href="https://www.youtube.com/channel/UCXZYBXNxKE02guTUKujOq7w" target="_blank" rel="noopener noreferrer"><i className="fab fa-youtube"></i></a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default MainComponent;
